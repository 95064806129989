export const TOOLBAR_TITLE = 'TOOLBAR_TITLE';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_UNLOAD = 'LOGIN_UNLOAD';
export const PROFILE_LOAD = 'PROFILE_LOAD';

// DIALOGS
export const CONFIRM_SHOW = 'CONFIRM_SHOW';
export const CONFIRM_CLOSE = 'CONFIRM_CLOSE';
export const ALERT = 'ALERT';
export const ALERT_CLOSE = 'ALERT_CLOSE';
export const PROMPT_SHOW = 'PROMPT_SHOW';
export const PROMPT_CLOSE = 'PROMPT_CLOSE';
export const TOAST = 'TOAST';
export const TOAST_CLOSE = 'TOAST_CLOSE';
