<template>
  <md-snackbar
    md-position="center"
    :md-duration="Infinity"
    :md-active="toast.visible"
    md-persistent
  >
    <span>{{$t(toast.message)}}</span>
  </md-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
import { TOAST_CLOSE } from "../../store/actions.types";
export default {
  name: "Toast",
  data() {
    return {
      timeout: null
    };
  },

  computed: {
    ...mapGetters({
      toast: "toast"
    })
  },

  watch: {
    toast: function() {
      if (this.toast.visible) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$store.dispatch(TOAST_CLOSE);
        }, 3000);
      }
    }
  }
};
</script>

<style>
</style>