export const TOOLBAR_UPDATE = 'TOOLBAR_UPDATE';

export const SET_PROFILE = 'SET_PROFILE';
export const UNSET_PROFILE = 'UNSET_PROFILE';

export const SET_CONFIRM = 'SET_CONFIRM';
export const HIDE_CONFIRM = 'HIDE_CONFIRM';
export const SET_ALERT = 'SET_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SET_PROMPT = 'SET_PROMPT';
export const HIDE_PROMPT = 'HIDE_PROMPT';
export const SET_TOAST = 'SET_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';