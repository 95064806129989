import { Token } from "../../common/token";

export function auth({ next }) {
	// Redirigir al Login
	if (!Token.get()) { // Redirigir al Login si no encuentra un token
		return next({ 
			name: 'Login'
		});
	}
	return next();
}