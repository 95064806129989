<template>
  <div>
    <div class="md-layout md-alignment-top-space-around">
      <home-packing-form-article
        class="md-layout-item md-size-20 md-medium-size-45 md-small-size-100"
        :article="form.article"
        :algorithms="form.algorithms"
        :validation="$v.form.article"
      />

      <home-packing-form-restrictions
        class="md-layout-item md-size-20 md-medium-size-45 md-small-size-100"
        :restrictions="form.restrictions"
        :validation="$v.form.restrictions"
      />

      <home-packing-form-other
        class="md-layout-item md-size-20 md-medium-size-45 md-small-size-100"
        :others="form.others"
        :validation="$v.form.others"
      />

      <home-packing-form-dispositions
        class="md-layout-item md-size-20 md-medium-size-45 md-small-size-100"
        :dipositions="form.dispositions"
        :validation="$v.form.dispositions"
      />

      <home-packing-form-algorithms
        class="md-layout-item md-size-95"
        :algorithms="form.algorithms"
        :validation="$v.form.algorithms"
      />
    </div>

    <div class="md-layout md-alignment-top-space-around">
      <home-packing-box-list
        class="md-layout-item md-size-95"
        :selectedBoxes="form.boxCodes"
      />
    </div>

    <!-- <pre> {{ form }} </pre> -->

    <div class="md-layout md-alignment-top-space-around">
      <md-button
        @click="calculate"
        :disabled="calculating || this.$v.form.$invalid"
        class="md-raised md-primary md-layout-item md-size-15 md-small-size-100 btn-calculate"
      >
        {{ $t("calculate") }}
      </md-button>

      <md-field
        class="md-raised md-primary md-layout-item md-size-95 md-small-size-100 result"
      >
        <label> {{ $t("result") }} </label>
        <md-textarea v-model="result" readonly></md-textarea>
      </md-field>
    </div>
  </div>
</template>

<script>
import { Packing } from "../../common/api/api.service";
import { ALERT } from "../../store/actions.types";
import HomePackingFormArticle from "./HomePackingFormArticle.vue";
import HomePackingFormDispositions from "./HomePackingFormDispositions.vue";
import HomePackingFormOther from "./HomePackingFormOther.vue";
import HomePackingFormRestrictions from "./HomePackingFormRestrictions.vue";

import {
  required,
  minValue,
  between,
  minLength,
} from "vuelidate/lib/validators";
import HomePackingBoxList from "./HomePackingBoxList.vue";
import HomePackingFormAlgorithms from "./HomePackingFormAlgorithms.vue";

export default {
  components: {
    HomePackingFormArticle,
    HomePackingFormRestrictions,
    HomePackingFormOther,
    HomePackingFormDispositions,
    HomePackingBoxList,
    HomePackingFormAlgorithms,
  },
  name: "HomePackingForm",
  data() {
    return {
      calculating: false,
      form: {
        article: {
          width: 0,
          length: 0,
          height: 0,
          weight: 0,
        },
        restrictions: {
          width: 0,
          length: 0,
          height: 0,
        },
        others: {
          minArticles: 0,
          minRatio: 1,
          maxIterations: 2,
          toleranceRatio: 0,
          maxResultPerBox: 5,
          maxBoxNumber: 5,
        },
        // dispositions: ["WLH", "LWH", "HWL", "WHL", "LHW", "HLW"],
        dispositions: {
          grid: ["WLH", "LWH", "HWL", "WHL", "LHW", "HLW"],
          cylindrical: ["W", "L", "HW", "HL"],
        },
        algorithms: [],
        boxCodes: [],
      },
      result: "",
    };
  },

  validations: {
    form: {
      article: {
        width: {
          required,
          minValue: minValue(1),
        },
        length: {
          required,
          minValue: minValue(1),
        },
        height: {
          required,
          minValue: minValue(1),
        },
        weight: {
          required,
          minValue: minValue(0),
        },
      },
      restrictions: {
        width: {
          required,
          between: between(0, 150),
        },
        length: {
          required,
          between: between(0, 150),
        },
        height: {
          required,
          between: between(0, 150),
        },
      },
      others: {
        minArticles: {
          required,
          minValue: minValue(0),
        },
        minRatio: {
          required,
          between: between(1, 100),
        },
        maxIterations: {
          required,
          minValue: minValue(0),
        },
        toleranceRatio: {
          required,
          minValue: minValue(0),
        },
        maxResultPerBox: {
          required,
          minValue: minValue(0),
        },
        maxBoxNumber: {
          required,
          minValue: minValue(0),
        },
      },
      dispositions: {
        required,
        minLength: minLength(1),
      },
      algorithms: {
        required,
        minLength: minLength(1),
      },
    },
  },

  beforeMount() {
    let form = JSON.parse(localStorage.getItem("packing_form_cache"));
    if (form) {
      if(typeof(form.others.maxArticles) !== 'undefined') localStorage.removeItem("packing_form_cache");
      else this.form = Object.assign(this.form, form);
    }
  },

  methods: {
    calculate() {
      this.calculating = true;

      let formJson = JSON.stringify(this.form);
      localStorage.setItem("packing_form_cache", formJson);

      let formCopy = JSON.parse(formJson);

      if (formCopy.article.ignoreMaxWeight) {
        formCopy.article.weight = 0;
      }

      if (!this.$v.form.$invalid) {
        Packing.calculate(formCopy)
          .then((res) => {
            this.result = res.data;
          })
          .catch(() => {
            this.$store.dispatch(ALERT, { message: "Error" });
          })
          .finally(() => {
            this.calculating = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.btn-calculate {
  margin: 0;
}
.result {
  margin-top: 25px;
}
textarea {
  width: 100%;
  height: 250px;
  resize: vertical;
}

.md-field >>> .md-textarea {
  max-height: unset;
  height: 400px;
}
</style>