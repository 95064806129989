<template>
  <div>
    <md-dialog :md-fullscreen="false" :md-active="alert.visible" @md-clicked-outside="onClose">
      <md-dialog-content class="text-block">{{$t(alert.message)}}</md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="onClose">{{$t('close')}}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ALERT_CLOSE } from "../../store/actions.types";

export default {
  name: "DialogAlert",
  computed: {
    ...mapGetters({
      alert: "alertDialog"
    })
  },
  methods: {
    onClose() {
      if (typeof this.alert.onClose === "function") {
        this.alert.onClose();
      }
      this.$store.dispatch(ALERT_CLOSE);
    }
  }
};
</script>

<style scoped>
.text-block {
  white-space: pre;
}
</style>