<template>
  <!-- v-model="value" -->

  <md-dialog :md-fullscreen="false" :md-active="dialog.visible">
    <md-dialog-title v-if="dialog.title">{{$t(dialog.title)}}</md-dialog-title>

    <md-dialog-content>
      <md-field>
        <label for="input-prompt" v-if="dialog.placeholder">{{$t(dialog.placeholder)}}</label>
        <md-input
          @keydown="detectEnter"
          ref="inputvalue"
          :type="dialog.password ? 'password' : 'text'"
          v-model="value"
        />
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button @click="onCancel()">{{$t(dialog.cancel)}}</md-button>
      <md-button
        :class="dialog.warning ? 'md-raised md-accent' : 'md-primary' "
        @click="onConfirm()"
      >{{$t(dialog.confirm)}}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { PROMPT_CLOSE } from "../../store/actions.types";
export default {
  name: "DialogPrompt",

  data() {
    return {
      value: ""
    };
  },

  mounted() {
    // this.$refs.inputValue.$el.focus()
  },

  computed: {
    ...mapGetters({
      dialog: "promptDialog"
    })
  },

  watch: {
    dialog: function() {
      if (this.dialog.visible) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.inputvalue.$el.focus();
          }, 50);
        });
      }
    }
  },

  methods: {
    onConfirm() {
      if (typeof this.dialog.onConfirm === "function") {
        this.dialog.onConfirm(this.value);
      }
      this.$store.dispatch(PROMPT_CLOSE);
      this.$forceUpdate();
      this.value = "";
    },

    onCancel() {
      if (typeof this.dialog.onCancel === "function") {
        this.dialog.onCancel();
      }
      this.$store.dispatch(PROMPT_CLOSE);
      this.$forceUpdate();
      this.value = "";
    },

    detectEnter(ev) {
      if (ev.keyCode === 13) {
        this.onConfirm();
      }
    }
  }
};
</script>

<style>
</style>