<template>
  <div>
    <md-menu>
      <md-button class="lang" md-menu-trigger>{{$i18n.locale}}</md-button>
      <md-menu-content>
        <md-menu-item v-for="(lang, i) in langs" :key="`Lang${i}`" @click="change(lang)">
          <span class="caps">{{lang}}</span>
        </md-menu-item>
      </md-menu-content>
    </md-menu>
  </div>
</template>

<script>
import ApiService from '../../common/api/api.service';
export default {
  name: "LocaleChanger",

  data() {
    return { 
      langs: ["en", "es"] ,
      locales: {
        en: "en-US",
        es: "es-ES"
      }
    };
  },

  mounted() {
    let lang = localStorage.getItem("lang");
    if (lang && this.langs.includes(lang)) {
      this.$i18n.locale = lang;
    }
    ApiService.setLocale(this.locales[this.$i18n.locale]);
  },

  methods: {
    change(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("lang", this.$i18n.locale);
      ApiService.setLocale(this.locales[this.$i18n.locale]);
    }
  }
};
</script>

<style scoped>
.lang {
  min-width: 45px;
}
</style>