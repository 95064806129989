<template>
  <div v-if="showList">
    <md-button class="md-raised show-btn" @click="showList = !showList">
      {{ $t("hide_box_selection") }}
    </md-button>
    <span class="selected">
      {{ $t("selected") }}: {{ selectedBoxes.length }}
    </span>

    <md-field>
      <label> {{ $t("search") }} </label>
      <md-input v-model="query"></md-input>
    </md-field>

    <md-table class="box-list">
      <md-table-row>
        <md-table-head>
          <font-awesome-icon @click="clear" icon="times" />
        </md-table-head>
        <md-table-head>Code</md-table-head>
        <md-table-head> {{ $t("width") }} </md-table-head>
        <md-table-head> {{ $t("height") }} </md-table-head>
        <md-table-head> {{ $t("length") }} </md-table-head>
        <md-table-head> {{ $t("weight") }} (g) </md-table-head>
      </md-table-row>

      <template v-for="(box, key) in boxes">
        <md-table-row :key="key" v-if="filterBox(box)">
          <md-table-cell>
            <md-checkbox v-model="selectedBoxes" :value="box.code" />
          </md-table-cell>
          <md-table-cell>{{ box.code }}</md-table-cell>
          <md-table-cell>{{ box.width }}</md-table-cell>
          <md-table-cell>{{ box.height }}</md-table-cell>
          <md-table-cell>{{ box.length }}</md-table-cell>
          <md-table-cell>{{ box.weight }}</md-table-cell>
        </md-table-row>
      </template>
    </md-table>
  </div>
  <div v-else>
    <md-button class="md-raised show-btn" @click="showList = !showList">
      {{ $t("show_box_selection") }}
    </md-button>
    <span class="selected">
      {{ $t("selected") }}: {{ selectedBoxes.length }}
    </span>
  </div>
</template>

<script>
import { Packing } from "../../common/api/api.service";
export default {
  name: "HomePackingBoxList",
  props: {
    selectedBoxes: Array,
  },

  data() {
    return {
      query: "",
      boxes: [],
      showList: false,
    };
  },

  mounted() {
    Packing.obtainBoxes().then((res) => {
      this.boxes = res.data;
    });
  },

  methods: {
    clear() {
      if (this.selectedBoxes.length === 0) {
        this.boxes.forEach((box) => {
          this.selectedBoxes.push(box.code);
        });
      } else {
        this.selectedBoxes.splice(0);
      }
    },

    filterBox(box) {
      return (
        !this.query || box.code.toLowerCase().includes(this.query.toLowerCase().trim())
      );
    },
  },
};
</script>

<style scoped>
.selected {
  position: absolute;
  padding-top: 25px;
}
.show-btn {
  margin-left: 0px;
}
.box-list {
  height: 400px;
}
</style>