import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { API_URL } from "../constants";
import { Interceptors } from "./api.interceptor";
import { Token } from "../token";
import qs from 'qs';
import store from "../../store";
import { PROFILE_LOAD } from "../../store/actions.types";

const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		Vue.axios.defaults.baseURL = API_URL;

		// Interceptor http 401
		Vue.axios.interceptors.response.use(Interceptors.responseSuccess, Interceptors.responseError);

		Vue.axios.defaults.headers.common['Cache-Control'] = 'no-cache';

		let token = Token.get();
		if (token) {
			this.setAuthHeader(token)
			store.dispatch(PROFILE_LOAD);
		}
	},

	setAuthHeader(token) {
		Vue.axios.defaults.headers.common['X-Auth-Token'] = token;
		return token;
	},

	setLocale(locale) {
		Vue.axios.defaults.headers.common['Accept-Language'] = locale;
	},

	query(resource, params) {
		return Vue.axios.get(resource, {
			params,
			paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
		});
	},

	get(resource) {
		return Vue.axios.get(resource);
	},

	post(resource, data) {
		return Vue.axios.post(resource, data);
	},

	update(resource, data) {
		return Vue.axios.put(resource, data);
	},

	put(resource, data) {
		return Vue.axios.put(resource, data);
	},

	delete(resource) {
		return Vue.axios.delete(resource);
	}
};

export default ApiService;

export const Auth = {
	login(credentials) {
		return ApiService.post('/auth/login', credentials);
	},
	logout() {
		return ApiService.get('/auth/logout');
	},
	verifyLogin() {
		return ApiService.get('/auth/verify');
	},
}

export const Packing = {
	obtainBoxes() {
		return ApiService.get('/packing/boxlist');
	},
	calculate(params) {
		return ApiService.post('/packing/calculate', params);
	}
}