import {
    CONFIRM_CLOSE, CONFIRM_SHOW, TOOLBAR_TITLE,
    ALERT, ALERT_CLOSE, PROMPT_SHOW, PROMPT_CLOSE, TOAST_CLOSE, TOAST
} from "../actions.types";
import {
    HIDE_CONFIRM, SET_CONFIRM, TOOLBAR_UPDATE,
    SET_ALERT, HIDE_ALERT, SET_PROMPT,
    HIDE_PROMPT, SET_TOAST, HIDE_TOAST
} from "../mutations.types";

const initialConfirmDialog = {
    visible: false,
    title: '',
    message: '',
    onConfirm: null,
    onCancel: null,
}

const initialAlertDialog = {
    visible: false,
    message: '',
    onClose: null
}

const initialPromptDialog = {
    visible: false,
    title: '',
    placeholder: '',
    password: false,
    confirm: '',
    cancel: '',
    onConfirm: null,
    onCancel: null
}

const initialToast = {
    visible: false,
    message: '',
}

const initialToolbar = {
    title: 'Title' // Default Title
}

const state = {
    toolbar: initialToolbar,
    confirmDialog: initialConfirmDialog,
    alertDialog: initialAlertDialog,
    promptDialog: initialPromptDialog,
    toast: initialToast
}

const actions = {
    [TOOLBAR_TITLE]({ commit }, title) {
        if (state.toolbar.title !== title) {
            commit(TOOLBAR_UPDATE, title);
        }
    },



    // Confirm dialog
    [CONFIRM_SHOW]({ commit }, data) {
        commit(SET_CONFIRM, data)
    },
    [CONFIRM_CLOSE]({ commit }) {
        commit(HIDE_CONFIRM);
    },

    // Alert dialog
    [ALERT]({ commit }, data) {
        // commit(SET_ALERT, data);

        if (data.response?.data?.message) {
            commit(SET_ALERT, { message: data.response.data.message }); // En llamadas http con error, spring añade message
        } else if (data.response?.data?.errors) {
            let errors = ""
            data.response?.data?.errors.forEach(element => {
                errors += element.message + "\n";
            });
            commit(SET_ALERT, { message: errors }); 
        } else {
            commit(SET_ALERT, data);
        }

    },
    [ALERT_CLOSE]({ commit }) {
        commit(HIDE_ALERT);
    },

    // Prompt dialog
    [PROMPT_SHOW]({ commit }, data) {
        commit(SET_PROMPT, data);
    },
    [PROMPT_CLOSE]({ commit }) {
        commit(HIDE_PROMPT);
    },

    // Toast message
    [TOAST]({ commit }, data) {
        commit(SET_TOAST, data);
    },
    [TOAST_CLOSE]({ commit }) {
        commit(HIDE_TOAST)
    }
}

const mutations = {
    [TOOLBAR_UPDATE](state, title) {
        state.toolbar.title = title;
    },

    // Dialog confirm
    [SET_CONFIRM](state, confirmDialog) {
        state.confirmDialog = confirmDialog;
        state.confirmDialog.visible = true;
    },
    [HIDE_CONFIRM](state) {
        // state.confirmDialog.visible = false;
        state.confirmDialog = initialConfirmDialog;
    },

    // Dialog alert
    [SET_ALERT](state, alertDialog) {
        state.alertDialog = alertDialog;
        state.alertDialog.visible = true;
    },
    [HIDE_ALERT](state) {
        state.alertDialog = initialAlertDialog;
    },

    // Dialog prompt
    [SET_PROMPT](state, promptDialog) {
        state.promptDialog = promptDialog;
        state.promptDialog.visible = true;
    },
    [HIDE_PROMPT](state) {
        state.promptDialog = initialPromptDialog;
    },

    // Toast message
    [SET_TOAST](state, toast) {
        state.toast = toast;
        state.toast.visible = true;
    },
    [HIDE_TOAST](state) {
        state.toast = initialToast;
    }
}

const getters = {
    toolbarTitle() {
        return state.toolbar.title;
    },
    confirmDialog() {
        return state.confirmDialog;
    },
    alertDialog() {
        return state.alertDialog;
    },
    promptDialog() {
        return state.promptDialog;
    },
    toast() {
        return state.toast;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};