import { render, staticRenderFns } from "./HomePackingForm.vue?vue&type=template&id=237c7955&scoped=true&"
import script from "./HomePackingForm.vue?vue&type=script&lang=js&"
export * from "./HomePackingForm.vue?vue&type=script&lang=js&"
import style0 from "./HomePackingForm.vue?vue&type=style&index=0&id=237c7955&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237c7955",
  null
  
)

export default component.exports