<template>
  <!-- Menú usuario en el header -->
  <div>
    <md-menu md-direction="bottom-end">
      <md-button md-menu-trigger class="md-icon-button">
        <md-icon>
          <font-awesome-icon icon="user-circle" />
        </md-icon>
      </md-button>

      <md-menu-content>
        <!-- Ítems del menú -->
        <md-menu-item v-if="user && user.name">
          <font-awesome-icon class="user-icon" icon="user-circle" />
          <span> {{ user.name }} </span>
        </md-menu-item>
        <md-menu-item @click="logout()">
          {{ $t("logout") }}
        </md-menu-item>
      </md-menu-content>
    </md-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { LOGOUT } from "@/store/actions.types.js";

export default {
  name: "UserMenu",
  computed: {
    ...mapState({
      user: (state) => state.auth.currentUser,
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
      this.$router.replace("/login");
    },
  },
};
</script>

<style>
.md-list-item-content {
  justify-content: normal;
}
.user-icon {
  margin-right: 5px;
}
</style>