<template>
  <div>
    <span class="md-title">{{ $t("others") }}</span>
    <div class="md-layout md-alignment-bottom-space-between">
      <div class="md-layout-item md-size-45">
        <md-field :class="{ 'md-invalid': validation.minArticles.$invalid }">
          <label> {{ $t("min_articles") }} (0 {{ $t("inactive") }})</label>
          <md-input
            min="0"
            max="2147483647"
            v-model.number="others.minArticles"
            type="number"
          />
        </md-field>
        <md-field :class="{ 'md-invalid': validation.minRatio.$invalid }">
          <label> {{ $t("min_ratio") }} (1-100)</label>
          <md-input
            min="0"
            max="2147483647"
            v-model.number="others.minRatio"
            type="number"
          />
        </md-field>
        <md-field :class="{ 'md-invalid': validation.maxIterations.$invalid }">
          <label> {{ $t("max_iterations") }}</label>
          <md-input
            min="0"
            max="2147483647"
            v-model.number="others.maxIterations"
            type="number"
          />
        </md-field>
      </div>

      <div class="md-layout-item md-size-45">
        <md-field :class="{ 'md-invalid': validation.toleranceRatio.$invalid }">
          <label> {{ $t("tolerance_ratio") }}</label>
          <md-input
            min="0"
            max="2147483647"
            v-model.number="others.toleranceRatio"
            type="number"
          />
        </md-field>
        <md-field
          :class="{ 'md-invalid': validation.maxResultPerBox.$invalid }"
        >
          <label> {{ $t("max_result_box") }}</label>
          <md-input
            min="0"
            max="2147483647"
            v-model.number="others.maxResultPerBox"
            type="number"
          />
        </md-field>
        <md-field :class="{ 'md-invalid': validation.maxBoxNumber.$invalid }">
          <label> {{ $t("max_box_number") }}</label>
          <md-input
            min="0"
            max="2147483647"
            v-model.number="others.maxBoxNumber"
            type="number"
          />
        </md-field>
      </div>
    </div>
    <md-checkbox v-model="others.onlyMinArticles">
      {{ $t("only_min_articles") }}
    </md-checkbox>
  </div>
</template>

<script>
export default {
  name: "HomePackingFormOther",
  props: {
    others: {
      type: Object,
      required: true,
    },
    validation: Object,
  },
};
</script>
