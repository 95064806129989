<template>
  <div>
    <span class="md-title">{{ $t("restrictions") }}</span>
    <p>% {{ $t("press") }} (0-50) - {{ $t("looseness") }} (101 - 150)</p>
    <md-field :class="{ 'md-invalid': validation.width.$invalid }">
      <label>{{ $t("width") }}</label>
      <md-input min="0" max="2147483647" v-model.number="restrictions.width" type="number" />
    </md-field>
    <md-field :class="{ 'md-invalid': validation.length.$invalid }">
      <label>{{ $t("length") }}</label>
      <md-input min="0" max="2147483647" v-model.number="restrictions.length" type="number" />
    </md-field>
    <md-field :class="{ 'md-invalid': validation.height.$invalid }">
      <label>{{ $t("height") }}</label>
      <md-input min="0" max="2147483647" v-model.number="restrictions.height" type="number" />
    </md-field>
  </div>
</template>

<script>
export default {
  name: "HomePackingFormRestrictions",
  props: {
    restrictions: {
      type: Object,
      required: true,
    },
    validation: Object
  },
};
</script>

<style>
</style>