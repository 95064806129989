<template>
  <div>
    <span class="md-title">{{ $t("article") }}</span>
    <md-field :class="{ 'md-invalid': validation.width.$invalid }">
      <label v-if="isQuincunx()"> A - Diámetro (mm) </label>
      <label v-else>A - {{ $t("width") }} (mm)</label>
      <md-input
        min="0"
        max="2147483647"
        v-model.number="article.width"
        type="number"
        @change="updatedValue"
      />
    </md-field>
    <md-field :class="{ 'md-invalid': validation.length.$invalid }">
      <label v-if="isQuincunx()"> B - {{ $t("height") }} (mm) </label>
      <label v-else>B - {{ $t("length") }} (mm)</label>
      <md-input
        min="0"
        max="2147483647"
        v-model.number="article.length"
        type="number"
      />
    </md-field>
    <md-field
      :class="{ 'md-invalid': validation.height.$invalid }"
      v-show="!isQuincunx()"
    >
      <label>C - {{ $t("height") }} (mm)</label>
      <md-input
        min="0"
        max="2147483647"
        v-model.number="article.height"
        type="number"
      />
    </md-field>
    <md-field :class="{ 'md-invalid': validation.weight.$invalid }">
      <label> {{ $t("weight") }} (g)</label>
      <md-input
        min="0"
        max="2147483647"
        v-model.number="article.weight"
        type="number"
      />
    </md-field>
    <md-checkbox v-model="article.ignoreMaxWeight">
      {{ $t("ignore_max_weight") }}
    </md-checkbox>
  </div>
</template>

<script>
export default {
  name: "HomePackingFormArticle",
  props: {
    article: {
      type: Object,
      required: true,
    },
    algorithms: Array,
    validation: Object,
  },

  data() {
    return {
      lastValue: null,
    };
  },

  watch: {
    algorithms: function () {
      if (this.isQuincunx()) {
        this.lastValue = this.article.height;
      } else if (this.lastValue) {
        this.$set(this.article, "height", this.lastValue);
      }
      this.updatedValue();
    },
  },

  methods: {
    isQuincunx() {
      return this.algorithms.includes("quincunx");
    },

    updatedValue() {
      if (this.isQuincunx()) {
        this.$set(this.article, "height", this.article.width);
      }
    },
  },
};
</script>
