import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import ApiService from './common/api/api.service'

import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
// import 'vue-material/dist/theme/default-dark.css';
import VueMaterial from 'vue-material';
import Vuelidate from 'vuelidate';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
	faBars, faTimes, faUserCircle
} from '@fortawesome/free-solid-svg-icons'

import {
  faQuestionCircle
} from '@fortawesome/free-regular-svg-icons'

import './style.css'

Vue.config.productionTip = false

ApiService.init();

Vue.use(VueMaterial);
Vue.use(Vuelidate);

// Utilizar en componentes:
// <font-awesome-icon icon="user-secret" />
library.add([
  faBars, faUserCircle, faTimes, faQuestionCircle
]);
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
