import router from "../../router";
import { Token } from "../token";
import ApiService from "./api.service";


export const Interceptors = {
	responseSuccess(res) {
		return res;
	},

	responseError(err) {
		// console.log("responseError", err.response?.status);
		if (err.response?.status === 401) {
			console.warn('Session expired');
			Token.remove();
			ApiService.setAuthHeader(null);

			if (router.currentRoute.name !== 'Login')
				router.push({ name: 'Login', params: { cause: "session_expired" } });
		}

		return Promise.reject(err); // Necesario para saber que la llamada ha fallado
	},

}

