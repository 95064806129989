<template>
  <div>
    <md-dialog :md-fullscreen="false" :md-active="confirm.visible" @md-clicked-outside="onCancel">
      <md-dialog-title v-if="confirm.title">{{$t(confirm.title)}}</md-dialog-title>
      <md-dialog-content v-if="confirm.message">{{$t(confirm.message)}}</md-dialog-content>

      <md-dialog-actions>
        <md-button @click="onCancel()">{{$t(confirm.cancel)}}</md-button>
        <md-button
          :class="confirm.warning ? 'md-raised md-accent' : 'md-primary' "
          @click="onConfirm()"
        >{{$t(confirm.confirm)}}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { CONFIRM_CLOSE } from "../../store/actions.types";

export default {
  name: "ConfirmDialog",
  computed: {
    ...mapState({
      confirm: state => state.layout.confirmDialog
    })
  },
  methods: {
    onCancel() {
      if (typeof this.confirm.onCancel === "function") {
        this.confirm.onCancel();
      }
      this.$store.dispatch(CONFIRM_CLOSE);
    },
    onConfirm() {
      if (typeof this.confirm.onConfirm === "function") {
        this.confirm.onConfirm();
      }
      this.$store.dispatch(CONFIRM_CLOSE);
    }
  }
};
</script>
