<template>
  <div>
    <p class="md-title md-layout-item md-size-100">
      {{ $t("algorithms") }}
    </p>
    <md-checkbox
      v-model="algorithms"
      value="grid"
      :class="{ error: validation.$invalid }"
    >
      {{ $t("grid") }}
    </md-checkbox>
    <md-checkbox
      v-model="algorithms"
      value="quincunx"
      :class="{ error: validation.$invalid }"
    >
      {{ $t("quincunx") }}
    </md-checkbox>
  </div>
</template>

<script>
export default {
  name: "HomePackingFormAlgorithms",
  props: {
    algorithms: {
      type: Array,
      required: true,
    },
    validation: Object,
  },
};
</script>