import ApiService, { Auth } from "../../common/api/api.service";
import { Token } from "../../common/token";
import { LOGIN, LOGOUT, PROFILE_LOAD } from "../actions.types";

const state = {}

const actions = {
    async [LOGIN](ignore, credentials) {
        let res = await Auth.login(credentials);
        let token = res.headers['x-auth-token'];
        Token.set(token);
        ApiService.setAuthHeader(token);
    },

    [PROFILE_LOAD]() {
        Auth.verifyLogin();
    },

    [LOGOUT]() {
        Auth.logout();
        Token.remove();
        ApiService.setAuthHeader(null);
    }
}

const mutations = {}

const getters = {}

export default {
    state,
    actions,
    mutations,
    getters
};