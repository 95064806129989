<template>
  <div class="md-layout">
    <span class="md-title md-layout-item md-size-100">
      {{ $t("allowed_dispositions") }}
      <home-packing-dispositions-help />
    </span>

    <p class="md-layout-item md-size-100">
      <b>{{ $t("grid") }}</b>
    </p>

    <div class="md-layout-item md-size-50">
      <md-checkbox
        class="md-primary md-layout-item md-size-100"
        :class="{ error: validation.$invalid }"
        v-model="dipositions.grid"
        value="WLH"
      >
        ABC
      </md-checkbox>
      <md-checkbox
        class="md-primary md-layout-item md-size-100"
        :class="{ error: validation.$invalid }"
        v-model="dipositions.grid"
        value="LWH"
      >
        BAC
      </md-checkbox>
      <md-checkbox
        class="md-primary md-layout-item md-size-100"
        :class="{ error: validation.$invalid }"
        v-model="dipositions.grid"
        value="HWL"
      >
        CAB
      </md-checkbox>
    </div>
    <div class="md-layout-item md-size-50">
      <md-checkbox
        class="md-primary md-layout-item md-size-100"
        :class="{ error: validation.$invalid }"
        v-model="dipositions.grid"
        value="WHL"
      >
        ACB
      </md-checkbox>
      <md-checkbox
        class="md-primary md-layout-item md-size-100"
        :class="{ error: validation.$invalid }"
        v-model="dipositions.grid"
        value="LHW"
      >
        BCA
      </md-checkbox>
      <md-checkbox
        class="md-primary md-layout-item md-size-100"
        :class="{ error: validation.$invalid }"
        v-model="dipositions.grid"
        value="HLW"
      >
        CBA
      </md-checkbox>
    </div>

    <p class="md-layout-item md-size-100">
      <b>{{ $t("cylindrical") }}</b>
    </p>
    <div class="md-layout-item md-size-50">
      <md-checkbox
        class="md-primary md-layout-item md-size-100"
        :class="{ error: validation.$invalid }"
        v-model="dipositions.cylindrical"
        value="W"
      >
        W
      </md-checkbox>
      <md-checkbox
        class="md-primary md-layout-item md-size-100"
        :class="{ error: validation.$invalid }"
        v-model="dipositions.cylindrical"
        value="HW"
      >
        HW
      </md-checkbox>
    </div>

    <div class="md-layout-item md-size-50">
      <md-checkbox
        class="md-primary md-layout-item md-size-100"
        :class="{ error: validation.$invalid }"
        v-model="dipositions.cylindrical"
        value="L"
      >
        L
      </md-checkbox>
      <md-checkbox
        class="md-primary md-layout-item md-size-100"
        :class="{ error: validation.$invalid }"
        v-model="dipositions.cylindrical"
        value="HL"
      >
        HL
      </md-checkbox>
    </div>
  </div>
</template>

<script>
import HomePackingDispositionsHelp from "./HomePackingDispositionsHelp.vue";
export default {
  components: { HomePackingDispositionsHelp },
  name: "HomePackingFormDispositions",
  props: {
    dipositions: {
      type: Object,
      required: true,
    },
    validation: Object,
  },
};
</script>
