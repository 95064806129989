import Vue from 'vue';
import Vuex from 'vuex';

import layout from './layout/layout.module';
import auth from './auth/auth.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    layout, 
    auth
  }
})
