import { version } from '../../package.json';

/**
 * Obtener URL del servidor
 */
function getApiUrl() {
    if (localStorage.getItem('API_URL')) {
        return localStorage.getItem('API_URL');
    } else if (window.webpackHotUpdate) {
        return 'http://localhost:8085/api'
    } else {
        if (location.protocol === 'https:') {
            return `https://${document.location.host.split(':')[0]}/api`
        } else {
            return `http://${document.location.host.split(':')[0]}/api`
        }
    }
}

export const API_URL = getApiUrl();
export const VERSION = version;
