<template>
  <div>
    <home-packing-form />
  </div>
</template>

<script>
import HomePackingForm from "../../components/home/HomePackingForm.vue";
import { TOOLBAR_TITLE } from "../../store/actions.types";

export default {
  name: "Home",
  components: {
    HomePackingForm,
  },

  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "home");
  },
};
</script>
