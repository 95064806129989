<template>
  <!-- md-mode="reveal" -->
  <md-app :md-scrollbar="false">
    <!-- Header -->
    <md-app-toolbar class="md-primary toolbar" v-show="showLayout()">
      <md-button class="md-icon-button btn-menu" @click="menuVisible = !menuVisible">
        <md-icon>
          <font-awesome-icon icon="bars" />
        </md-icon>
      </md-button>
      <h1 class="md-title toolbar-title"> {{$t(title)}} </h1>

      <div class="md-toolbar-section-end">
        <LocaleChanger />
        <UserMenu />
      </div>
    </md-app-toolbar>

    <!-- Menú lateral -->

    <md-app-drawer :md-active.sync="menuVisible" v-show="showLayout()" md-permanent="full">
      <div class="left-menu">
        <!-- Contenido Menú lateral -->
        <md-toolbar class="md-transparent logo" md-elevation="1">
          <img src="../../assets/logo_rielec.png" />
          <small>V {{version}} </small>
        </md-toolbar>

        <LayoutLinks class="md-layout-item md-size-100" @clicked="hide" />
      </div>
    </md-app-drawer>

    <md-app-content>
      <!-- App content -->
      <router-view />
    </md-app-content>
  </md-app>
</template>

<script>
import UserMenu from "@/components/layout/UserMenu";
import LayoutLinks from "@/components/layout/LayoutLinks";
import LocaleChanger from "@/components/layout/LocaleChanger";
import { mapGetters } from "vuex";
import { VERSION } from "@/common/constants.js";

export default {
  name: "Layout",
  components: {
    UserMenu,
    LayoutLinks,
    LocaleChanger
  },
  computed: {
    ...mapGetters({
      title: "toolbarTitle"
    })
  },
  data: () => ({
    menuVisible: false,
    version: VERSION
  }),
  methods: {
    showLayout() {
      return this.$router.currentRoute.name !== "Login";
    },
    hide() {
      this.menuVisible = false;
    }
  }
};
</script>

<style src="./Layout.css" scoped>